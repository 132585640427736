<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12.mb-2.title-box
      b-button(variant="secondary" to="/program-schedule") 回上一層
    .col-lg-12
      b-card.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-title 基本設定

        b-card-body
          form.form-horizontal(role="form")
            .row
              .col-lg-4
                .form-group.mb-3
                  label 節目表名稱
                  br
                  input.form-control(
                    v-model="program.name",
                    type="text",
                    placeholder="請輸入節目表名稱",
                  )
              .col-lg-4
                .form-group.mb-3
                  label.form-label-row 
                    span 自動啟用時間
                    b-form-checkbox(
                      v-model="off_check",
                      name="checkbox",
                      value="accepted",
                      unchecked-value="not_accepted"
                      @change="resetStartDate"
                    ) 不自動啟用
                  date-picker(
                    ref="ProgramListAutoStartAt"
                    type="datetime"
                    v-model="program.start_datetime", 
                    append-to-body, 
                    lang="en", 
                    confirm, 
                    :is-24="true"
                    format="YYYY-MM-DD HH:mm"
                    placeholder="請選擇自動啟用時間", 
                    :disabled="off_check === 'accepted'"
                    @change="resetStartDate"
                  )
    .col-lg-12.mt-2
      b-card.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-title 內容設定

        b-card-body
          .row
            .col.text-right
              b-button.width-lg.mb-2(variant="secondary" @click="addNewRow()") 新增一列

          b-table(
            :items="tableData", 
            :fields="fields", 
            responsive 
            v-if="tableData.length > 0"
          )
            template(v-slot:cell(start_time)="row")
              date-picker(
                v-model="row.item.start_time",
                format="HH:mm",
                :is-24="true"
                value-type="format",
                type="time",
                placeholder="請選擇時間",
              )
            template(v-slot:cell(image)="row")
              SelectImage(
                type="image"
                :value="row.item.currentImage"
                :key="'MediaLibraryImage' + row.index"
                :itemKey="'MediaLibraryImage' + row.index"
                :index="row.index"
                @upDateImage="setCurrentImage"
              )
            template(v-slot:cell(program_name)="row")
              input.form-control(
                v-model="row.item.program_name",
                type="text",
                placeholder="請輸入名稱，限制8個字",
                )
            template(v-slot:cell(ytvideo_uri)="row")
              input.form-control(
                v-model="row.item.ytvideo_uri",
                type="text",
                placeholder="（選填）請輸入YT對應網址",
                )
            //- 編輯
            template(v-slot:cell(edit)="row")
              .create(v-if="!row.item.id")
                b-button.table-btn.mr-2(@click="addItem(row.item)")
                  | 建立
                b-button.table-btn.mr-2(@click="deleteRow(row.index)")
                  | 取消
              .update(v-else)
                b-button.table-btn.mr-2(@click="putItem(row.item)")
                  | 更新
                b-button.table-btn.mr-2(@click="delItem(row.item)")
                  | 移除
      .row.text-center.mt-3
        .col
          b-button.width-lg.mr-1(variant="primary",@click="saveDetail") 儲存
          b-button.width-lg(variant="danger",@click="removeProgramItem") 刪除
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import Select2 from 'v-select2-component';
import SelectImage from "@/components/select-image";

/**
 * Starter component
 */
export default {
  name: 'ProgramScheduleEdit',
  data () {
    return {
      title: '編輯直播節目表',
      ProgramItemID: false,
      items: [
        {
          text: "直播管理",
          href: "/livestream",
        },
        {
          text: "專區首頁編輯",
          href: '/livestream',
        },
        {
          text: "編輯節目表",
          active: true,
        }
      ],
      value: '',
      date: '',
      time: '',
      visible: true,
      option: '',
      off_check: 'accepted',
      program: {},
      tableData: [],
      fields: [
        { key: "start_time", label: "播放時段", sortable: false, class: 'width-auto center' },
        { key: "image", label: "封面", sortable: false, class: 'width-auto center' },
        { key: "program_name", label: "節目名稱", sortable: false, class: 'width-auto center' },
        { key: "ytvideo_uri", label: "YT對應網址", sortable: false, class: 'width-auto center' },
        { key: "edit", label: "操作", sortable: false, class: 'width-auto center' }
      ],
    };
  },
  computed: {

  },
  mounted () {

  },
  components: {
    Layout,
    PageHeader,
    SelectImage,
    Multiselect,
    DatePicker,
    Select2,
  },
  watch: {

  },
  async created () {
    this.ProgramItemID = await this.$route.params.id

    // 直接建立新的節目表資料
    if (this.ProgramItemID === 'create') {
      await this.createProgram()
    }

    await this.getLiveProgramDetail()
    await this.getProgramItemData()
  },
  methods: {
    // 獲取節目表資訊
    getLiveProgramDetail () {
      if (this.ProgramItemID === 'create') {
        return
      }

      let vm = this
      this.$root.apis.getLiveProgramDetail(this.ProgramItemID, function (_response) {
        let program = _response.body.data
        // 決定不自動啟動狀態
        vm.off_check = (!program.start_date) ? 'accepted' : 'not_accepted'
        // 處理自動更新時間
        program.start_datetime = (program.start_date) ? vm.$root.common.getNowTime('Object', program.start_date + ' ' + program.start_time) : null
        // 更新節目資料
        vm.program = program
      })
    },
    // 獲取節目表項目清單
    getProgramItemData () {
      if (this.ProgramItemID === 'create') {
        return
      }

      let vm = this
      this.$root.apis.getLiveProgramItemList(
        this.ProgramItemID,
        function (_response) {
          let programItems = _response.body.data

          programItems.forEach(function (_item, _index) {
            programItems[_index].currentImage = false
            if (_item.image) {
              programItems[_index].currentImage = { url: _item.image }
            }
          })

          vm.tableData = programItems
        })
    },
    // 設置節目表項目圖片
    setCurrentImage (_image, _index) {
      this.tableData[_index].currentImage = _image
    },
    // 增加一個項目
    addNewRow () {
      const new_one = {
        id: "",
        liveprogram_id: "",
        start_time: "",
        image: "",
        currentImage: false,
        program_name: "",
        ytvideo_uri: null
      }
      this.tableData.push(new_one)
    },
    // 移除一個項目
    deleteRow (_index) {
      this.tableData.splice(_index, 1)
    },
    // 重新設定關於自動起始日的項目
    resetStartDate () {
      if (this.off_check === "accepted") {
        this.program.start_datetime = null
      }
      this.$forceUpdate()
    },
    // 儲存節目表資訊
    saveDetail () {
      let detail = {
        name: this.program.name,
        auto_start: (this.off_check === 'not_accepted') ? true : false,
        start_date: (this.off_check === 'not_accepted') ? this.$root.common.getNowTime('date', this.program.start_datetime) : "",
        start_time: (this.off_check === 'not_accepted') ? this.$root.common.getNowTime('time', this.program.start_datetime) : ""
      }

      let vm = this
      this.$root.apis.putLiveProgramDetail(this.ProgramItemID, detail, function (_response) {
        vm.$root.common.showSingleNotify("節目表資訊更新成功！")
      })
    },
    // 建立一個節目表以供編輯
    async createProgram () {
      let vm = this
      await this.$root.apis.addLiveProgramDetail(async function (_response) {
        vm.ProgramItemID = await _response.body.data
        vm.$router.push({ name: 'ProgramScheduleEditor', params: { id: _response.body.data } })
      })
    },
    // 移除直播節目表
    removeProgramItem () {
      let vm = this
      this.$root.common.confirmAction(
        "移除直播節目播放清單",
        "你確定要移除這個直播節目表嗎？",
        "確定移除",
        "下次再說",
        function () {
          vm.$root.apis.delLiveProgramList(vm.ProgramItemID, function () {
            vm.$root.common.showSingleNotify("直播節目表移除成功！")
            vm.$router.push({ name: 'ProgramSchedule', params: {} })
          })
        }
      )
    },
    // 建立一個節目表項目
    addItem (_item) {
      let vm = this
      let detail = {
        start_time: _item.start_time,
        image: _item.currentImage.url,
        program_name: _item.program_name,
        youtube_video_uri: _item.ytvideo_uri
      }
      this.$root.apis.addProgramItem(this.ProgramItemID, detail
        , function (_response) {
          vm.getProgramItemData()
          vm.$root.common.showSingleNotify("節目表項目建立成功！")
        })
    },
    // 更新一個節目表項目
    putItem (_item) {
      let vm = this
      let detail = {
        start_time: _item.start_time,
        image: _item.currentImage.url,
        program_name: _item.program_name,
        youtube_video_uri: _item.ytvideo_uri
      }
      this.$root.apis.putProgramItem(this.ProgramItemID, _item.id, detail
        , function (_response) {
          vm.getProgramItemData()
          vm.$root.common.showSingleNotify("節目表項目更新成功！")
        })
    },
    // 移除一個節目表項目
    delItem (_item) {
      let vm = this
      this.$root.common.confirmAction(
        "移除直播節目播放項目",
        "你確定要移除這個節目項目嗎？",
        "確定移除",
        "下次再說",
        function () {
          vm.$root.apis.delProgramItem(vm.ProgramItemID, _item.id, function (_response) {
            vm.getProgramItemData()
            vm.$root.common.showSingleNotify("節目表項目移除成功！")
          })
        }
      )
    },
  }
};
</script>

<style lang="sass" scoped>
.title-box
  display: flex
  justify-content: space-between
  align-items: center
  .addbtn
    display: flex
    justify-content: center
    align-items: center
    .ri-file-add-fill
      font-size: 1.4rem
      line-height: 1.5rem
.card-header
  background: #fff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1

.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)

.form-label-row
  display: flex
  gap: 14px

.add-btn
  width: 100%
  border: dashed 1px
.del-btn
  flex: 1
  display: flex
  align-items: center
  position: relative
  top: 3px
  .btn
    padding: 0rem .3rem
    border-radius: 50px
.img-view
  width: 400px
  height: 300px
  background: #e2e8f0
  border-radius: 5px
  margin-top: 5px
  border: 1px solid #ccc
  display: flex
  align-items: center
  justify-content: center
  position: relative
  i
    font-size: 30px

.gray-box
  background: #f1f5f9
  width: 100%
  padding: 10px
  display: flex
  justify-content: center

::v-deep .select2-container--default
  display: block
  width: 100%
  height: calc(1.5em + 0.9rem + 2px)
  // padding: 0.45rem 0.9rem
  font-size: 0.9rem
  font-weight: 400
  line-height: 1.5
  color: #6c757d
  background-color: #fff
  background-clip: padding-box
  border: 1px solid #ced4da
  border-radius: 0.2rem
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
  outline: none

::v-deep .select2-selection--single
  border: none
  display: flex
  align-items: center
  height: 100%
.form-label-row
  display: flex
  gap: 14px
::v-deep .table-btn
  text-decoration: underline
  color: #0073b8
  background: none
  border: none
  padding: 0
::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center
</style>
